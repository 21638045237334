.ui.segment.segmentlocation {
    padding-left: 1em;
}

.ui.button.buttonmargin {
    margin-right: 12px;
}

.ui.grid.container.bacnkaccountDataGrid {
    padding-left: 0px;
    padding-top: 29px;
}

.ui.list>.item.walletIDItem {
    align-items: center;
    display: flex;
}

#wallet-clipboard-button {
    box-shadow: none !important;
}

#bankaccountdetails .ui.grid>.row.mobileaccountdetailsRow {
    padding-bottom: 0px;
}

#bankaccountdetails .ui.grid>.row>.column.recievemoneyClmn {
    padding-right: 8px;
}

#bankaccountdetails .ui.grid>.row>.column.sendmoneyClmn {
    padding-left: 8px;
}

#bankaccountdetails .ui.grid>.row>.column.exchangeClmn {
	margin-top: 4px;
    padding-right: 8px;
}

#bankaccountdetails div.accountdetailsLI {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    overflow-wrap: break-word;
}

@media only screen and (max-width: 767px) {
    /* Send money button icon */
    #bankaccountdetails .ui.button:not(.icon)>.right.icon:not(.button):not(.dropdown) {
        margin-right: 0.5em;
        margin-bottom: 0.3em;
    }
    /* Dropdown segment */
    #bankaccountdetails .ui.segment.myaccountsegment {
        display: flex;
    }
    /* Request money button icon */
    #bankaccountdetails .ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
        margin-right: -0.3em;
        margin-bottom: 0.4em;
    }
    /* Request money button */
    #bankaccountdetails .ui.basic.button {
        box-shadow: none;
    }
    /* Send money button */
    #bankaccountdetails .ui.basic.button {
        box-shadow: none;
    }
    /* Deposit button */
    #bankaccountdetails .ui.basic.button.depositbuttonmobile {
        margin-top: -0.5em;
    }
    #bankaccountdetails .ui.attached.tabular.menu {
        visibility: hidden !important;
    }
    #bankaccountdetails p.walletidP {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
    }
    #bankaccountdetails .ui.grid>.row.walletidBTNRow {
        padding-top: 0px;
    }
    #bankaccountdetails .ui.basic.button.recievebuttonmobile {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }
    #bankaccountdetails .ui.basic.button.sendbuttonmobile {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }
}
