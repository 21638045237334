#crypto-trading a.active.item {
    background-color: rgba(0, 132, 160, 0.7);
}

#crypto-trading a.item {
    background-color: #F1F4F7;
    margin: 10px;
}

#crypto-trading .ui.stackable.grid>.column.cardcontainerCLMN:not(.row) {
    margin-left: -4px;
}

#crypto-trading .ui.basic.segment.cardsettingSegment {
    padding: 0px;
}

#crypto-trading .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.field.cardsettingLBL {
    margin-bottom: 5px;
}

#crypto-trading .ui.form .field .ui.input.currencyInput input {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
}

#crypto-trading {
    /*max-width: 260px;*/
}
#crypto-trading div .moneyTopupAmount {
    display: flex;
    align-items: baseline;
}

#crypto-trading div .accountTopupSelect .ui.selection.dropdown {
    padding-right: 1em;
}

#crypto-trading div .accountTopupSelect i {
    float: right;
}

#crypto-trading .ui.header.currencyH2 {
    margin-right: 8px;
    margin-bottom: 0px;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: right;
}

#crypto-trading .ui.primary.button.topUpBTN {
    margin-bottom: 16px;
}

#crypto-trading .ui.form .field.fromAccountLabel {
    margin-bottom: 4px;
}
