#tokens .balance-value {
	font-weight: 600;
	font-size: 22px;
	line-height: 30px;
}


#tokens .token-table-headers {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: none;
	padding-left: 1rem;
}

#tokens .token-no-borders {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: none;
}


#tokens .token-name-cell {
	display: flex;
	align-items: center;
}

#tokens .token-avatar {
	margin-right: 10px;
}

#tokens .display-linebreak {
	white-space: pre-line;
}

#tokens .token-bold {
	font-weight: bold;
}


#tokens .token-segment-wrap {
	padding-left: 0;
	padding-right: 0;
}

#tokens .token-disabled {
	opacity: 1;
}

#tokens .token-trade-window {
	padding: 24px;
	max-width: 540px;
}

#tokens .tokens-trade-divider,
#token-final-confirm .tokens-trade-divider {
	margin-top: 24px;
}

#tokens .tokens-trade-submit,
#token-final-confirm .tokens-trade-submit {
	margin-top: 24px;
}

#tokens .tokenTopup {
	display: flex;
	align-items: baseline;
	padding-bottom: 34px;
}

#tokens .tokenTopup>div {
	width: 50%;
}

#tokens .token-trade-window div .tokenTopup .error.field {
	width: 50%;
}


#tokens .token-error {
	font-size: 0.85714286rem;
	font-weight: bold;
}

#tokens .currencyLabel {
	width: 50%;
	justify-self: flex-end;
	padding-left: 8px;
	margin-bottom: 0;
}

#tokens .token-trade-window .big-amount-input input {
	width: 50%;
	justify-self: flex-start;
	text-align: right;
	font-weight: 600;
	color: inherit;
}

#tokens .big-color-font {
	font-size: 28px;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: 0;
}


#tokens .token-trx-header {
	cursor: pointer;
}


@media only screen and (max-width: 767px) {
	#tokens .token-trade .ui.grid>.column {
		width: 100% !important;
	} 

	#tokens .token-trade .token-menu {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}