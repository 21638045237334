.ui.segment.policyContent {
    padding: 2rem;
}

.policyContent td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.policyContent th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}