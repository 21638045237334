#individual-verification .segment {
    max-width: 70%;
}

#individual-verification .header {
    font-size: 28px;
}

.continue-button {
    min-width: 200px;
}
