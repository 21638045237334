.ui.header.cospayuserheader:first-child {
    margin-bottom: -0.7em;
}

.ui.input.transferamountinput>input {
    max-width: 50%;
}

#uploadButton .icon {
    padding-right: 15px;
}

@media only screen and (max-width: 767px) {
    #sendmoneynew .ui.grid>[class*="twelve wide"].column {
        width: 100% !important;
    }
    #sendmoneynew .ui.grid>.column:not(.row) {
        padding: 0;
    }
    #sendmoneynew .ui.segment {
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
}
