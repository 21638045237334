#mycontacts .ui.large.list {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

#mycontacts .ui.grid>.row>[class*="six wide"].column {
  padding-left: 20px;
}

#mycontacts div .mycontactlistheader {
  margin-left: 9px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.ui.header.newcontactH {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

#createContact .ui.form .field>label {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

#createContact .ui.grid>.column.createContactCLMN:not(.row) {
  padding-left: 0px;
}

#createContact .ui.input.disabled {
  opacity: unset;
  font-weight: 500;
}

#createContact .ui.form .field :disabled {
  opacity: 0.7;
}

#desktopContactList .content.contactName {
  color: rgba(0, 0, 0, 0.87);
}

#mycontacts .ui.primary.button.createContactBTN {
  margin-right: 8px;
}

#createContact .ui.form .field>.selection.dropdown>i.icon {
  float: right;
}

#createContact .ui.form .field>.selection.dropdown {
  padding-right: 13.5px;
}

#mycontacts .contactListHeader {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-left: 8px;
  margin-bottom: 8px;
}

#mycontacts .ui.header.myContactsHeader {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

#my-contacts-country-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

@media only screen and (max-width: 767px) {
  #mycontacts .ui.primary.button.createContactBTN {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    justify-content: center;
    padding: 7px 16px;
    width: 165.5px;
    height: 36px;
    border-radius: 6px;
    margin-right: 0px;
  }
  #mycontacts .ui.grid>.row>.column.createContactCLMN:not(.row) {
    padding-left: 7px;
  }
  #mycontacts .ui.header.myContactsHeader {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    padding-left: 24px;
  }
  #mycontacts .ui.primary.button.inviteContactBTN {
    height: 36px;
    width: 165.5px;
    left: 0px;
    top: 0px;
    border-radius: 6px;
    padding: 7px, 16px, 7px, 16px;
    margin-right: 10px;
  }
  #mycontacts .ui.grid>.row>.column.contactBTNCLMN {
    display: flex;
    padding-top: 0px !important;
  }
}
