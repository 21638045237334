
.ui.modal>.header.twofaheader {
  border-bottom: none !important;
}

.ui.modal>.header.twofaheader .closeIcon:hover {
  cursor: pointer;
}

.ui.label.twofapasswordlabel {
  background-color: transparent;
  margin-left: -0.8em;
}

.ui.form.twofaform .field .prompt.label {
  margin-left: -0.8em;
}

.ui.large.form.twofaproblem {
  font-size: 2rem;
}

.ui.modal>.content.twofacontent {
  padding-top: 0em;
}

a.twofaproblem {
  color: black;
}

.ui.large.form.twofaform {
  font-size: 14px;
}
