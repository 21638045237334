#accounts-tabs {
    position: relative;
}

#mobile-accounts-tabs {
    display: none;
}

@media only screen and (max-width: 767px) {
    #accounts-tabs #scrollable-tabs {
        display: none;
    }
}

#accounts-tabs .tabs {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}

#accounts-tabs .tabs .item.active {
    font-weight: inherit;
    margin-bottom: inherit;
}

#accounts-tabs .tabs .item {
    height: 106px;
    cursor: pointer;
}

#accounts-tabs .tabs .new button {
    box-shadow: none !important;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    padding: 0 2rem;
    border-left: 1px solid;
    border-radius: 0;
}

#accounts-tabs .tabs .new button i {
    margin-bottom: 0.6rem;
}

#accounts-tabs .tabs .item>div>div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 232px;
}

#accounts-tabs .tabs .item .tab-name,
#mobile-account-dropdown .tab-name {
    font-size: 18px;
    line-height: 26px;
}

#accounts-tabs .tabs .item .tab-value,
#mobile-account-dropdown .tab-value {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

#accounts-tabs .tabs .item .tab-hold,
#mobile-account-dropdown .tab-hold {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

#mobile-account-dropdown div[role="listbox"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#mobile-account-dropdown div[role="listbox"] i {
    transform: scale(1.5) translateY(-50%);
    position: absolute;
    top: 50%;
    right: 1em;
}

#accounts-tabs .left-arrow {
    top: 0;
    left: 0;
}

#accounts-tabs .right-arrow {
    top: 0;
    right: 0;
}

#accounts-tabs .tabs-arrow {
    height: 106px;
    width: 36px;
    z-index: 1;
    position: absolute;
}

#accounts-tabs .tabs-arrow button {
    margin-top: 30px;
}

#scrollable-tabs .edit-account-label {
    padding: 0.25em;
}


#deposit-vault .ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
    opacity: 1;
}

#deposit-vault .field input:disabled {
    opacity: 1;
}