.horisontal-group button:not(:first-child) {
    margin-left: 0.8em;
}

#cardsettingsDashboard .ui.stackable.grid>.column.cardcontainerCLMN:not(.row) {
    margin-left: -4px;
}

#cardsettingsDashboard .ui.basic.segment.cardsettingSegment {
    padding: 0px;
}

#cardsettingsDashboard .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.field.cardsettingLBL {
    margin-bottom: 5px;
}

#cardsettingsDashboard .ui.form .field .ui.input.currencyInput input {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
    width: 100%;
}

#cardsettingsDashboard div .moneyTopupAmount {
    display: flex;
    align-items: baseline;
}

#cardsettingsDashboard div .accountTopupSelect .ui.selection.dropdown {
    padding-right: 1em;
}

#cardsettingsDashboard div .accountTopupSelect i {
    float: right;
}

#cardsettingsDashboard .ui.header.currencyH2 {
    margin-right: 8px;
    margin-bottom: 0px;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
}

#cardsettingsDashboard .ui.primary.button.topUpBTN {
    margin-bottom: 16px;
}

#carsettingsDashboard i.icon:not(.backIcon) {
    float: right !important;
}

div.cardOptionBTNs {
    display: flex;
    margin-top: 16px;
    margin-bottom: 8px;
    margin-right: -3px;
}

#cardsettingsDashboard .ui.form .field.fromAccountLabel {
    margin-bottom: 4px;
}

.ui.segment.cardOptionSegment {
    display: inline-block;
}

#cardsettingsDashboard .ui.basic.button.cardSettingsBTNComputer {
    display: flex;
    align-items: center;
    height: 36px;
    border-radius: 6px;
}

svg.cardSettingsIcon:not(:root) {
    margin-right: 10px;
}

.ui.label.changecardLBL {
    padding: 0px;
    margin-left: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

p.cardsettingsP {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 8px;
}

.ui.primary.button.saveBTN {
    margin-right: 8px;
}

.ui.basic.button.closeBTN, .ui.basic.button.closeBTN:hover {
    box-shadow: 0px 0px 0px 0px inset;
    padding-top: 20px;
}

.ui.label.LBLCode {
    background: transparent;
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

p.terminateMSG {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

p.LBLCodeMSG {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.ui.input.codeInput {
    margin-right: 10px;
}

.ui.grid>.row>.column.codeInputCLMN {
    padding-right: 0px;
}

#cardsettingsDashboard .ui.basic.button.freezeBTN {
    height: 36px;
    border-radius: 6px;
}

#cardsettingsDashboard .ui.basic.button.sendcarddetailsBTN {
    height: 36px;
    border-radius: 6px;
}

#cardsettingsDashboard .ui.image.cardImage {
    margin-bottom: 16px;
}

#cardsettingsDashboard .ui.fluid.button.terminateCardBTN {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
}

#cardsettingsDashboard .ui.fluid.button.sendDetailsBTN {
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
}

#cardsettingsDashboard .ui.fluid.button.freezeCardBTN {
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
}

.ui.modal>.content.sendDetailsContent {
    text-align: center;
}

.ui.button.closeWindowBTN {
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    margin-bottom: 48px;
    margin-top: 24px;
}

.ui.button.closeTargetBTN, .ui.button.closeTargetBTN:hover {
    background: transparent;
    padding: 0px;
    margin-top: 20px;
    margin-left: 20px;
}

.ui.header.cardDetailsHeader {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 10px;
}

p.cardDetailsPhone {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

#terminateModal .ui.label.emailLabel {
    background: none;
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

#terminateModal .ui.grid>.column.terminateInputCLMN:not(.row) {
    padding-right: 0px;
}

#terminateModal p.terminateP {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

#terminateModal .ui.header.terminateCardHeader {
    margin-top: 0px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
}

#terminateModal .ui.button.terminateBTN {
    margin-right: 12px;
}

#cardDashboard .ui.header.activatecardHeader {
    padding-bottom: 20px;
}

.cardOptionSegment .cardPhoneNumber {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.cardOptionSegment .cardPhoneNumber button.edit-card-phone {
    border: none !important;
    padding: 0px;
}

.cardType {
    float: right;
}

.cardType p {
    display: inline-block;
    vertical-align: top;
    padding-right: 0.25rem;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}

.cardTypeValue p {
    display: inline-block;
    vertical-align: top;
    padding-right: 0.25rem;
    margin-bottom: 0px;
}


#cardsettingsDashboard .amount-error-prompt  { 
   background-color: transparent; 
   padding-left: 0;
}

@media only screen and (max-width: 767px) {
    .ui.image.cardImage {
        max-height: 290px;
    }
    #cardsettingsDashboard .ui.basic.button.cardSettingsBTN {
        height: 36px;
        border-radius: 6px;
        margin-right: 4px;
    }
    #cardsettingsDashboard .ui.basic.button.freezeBTN {
        height: 36px;
        border-radius: 6px;
        margin-left: 4px;
    }
    #cardsettingsDashboard .ui.basic.button.sendcarddetailsBTN {
        height: 36px;
        border-radius: 6px;
    }

    .cardType {
        float: none;
    }

    #cardsettingsDashboard .ui.form .field .ui.input.currencyInput input {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    svg#inactive-card {
        width: 552px;
        height: 159px;
        padding-left: -10px;
    }
}
