.formBTNS {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 1rem;
}

#kybForm.ui.form .field .prompt.label {
    border: none !important;
    padding: 0;
}

#kybForm.ui.form .field .prompt.label:before {
    display: none;
}

#kybForm.ui.form .field>label, #kybForm.ui.form label {
    font-size: 0.85em !important;
    font-weight: bold;
}

#kybForm.ui.form .field {
    clear: both;
    margin: 0 0 1em !important;
}

#kybForm.ui.form .field:last-child, .ui.form .fields:last-child .field {
    margin-bottom: unset;
}