#navbar.ui.menu {
    margin-bottom: 3em;
}

#navbar.ui.menu .active.item.logo {
    background-color: #ffffff;
}

#navbar.ui.menu .active.item.logo:hover {
    background-color: #ffffff;
}

#navbar.ui.menu .active.item.navbaritem {
    background: none;
}

#navbar.ui.menu .item.navbaritem svg {
    margin-right: 6px;
}

#navbar.ui.menu .item>i.icon {
    visibility: hidden;
}

#navbar .item-navbarDropdown {
	padding: 0;
}

svg:not(:root) .mobileNavbarIcon {
    display: none;
}

.ui.button.mobileNavbarBTN {
    display: none;
}

svg.mobileLogo:not(:root) {
    display: none;
}

@media only screen and (max-width: 767px) {
    #mobileNavbar {
        margin-left: auto;
    }

    #navbar.ui.stackable.menu .right.menu, #navbar.ui.stackable.menu .right.item {
        content-visibility: hidden;
        display: none;
    }
    .ui.button.mobileNavbarBTN {
        float: right;
        padding: 0px;
        display: initial;
        background: transparent;
    }

    .ui.button.mobileNavbarBTN.hide {
        display: none;
    }

    .ui.modal.mobileNavbarModal {
        width: 100%;
        height: 100%;
    }

    div .mobileNavbarBTNDiv {
        display: flex;
        align-items: center;
    }
    .menuP {
        margin-left: 9px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    .ui.button.closeMobileNavbarBTN {
        background: transparent;
        padding: 0px;
        float: right;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-top: 2px;
        margin-left: auto;
    }
    svg.closeNavbarModalIcon:not(:root) {
        margin-right: -8px;
    }
    #navbarModalMobile .dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
        padding: 0px;
    }
    .modals.dimmer .ui.scrolling.modal.mobileNavbarModal {
        margin-top: 0px;
    }
    .ui.modal>.header.navbarModalHeader {
        display: flex;
        align-items: center;
        padding-top: 10px !important;
        padding-left: 17px !important;
        padding-bottom: 13px !important;
    }
    .ui.modal>svg.mobileLogo:not(:root) {
        margin-left: 20px;
    }
    .ui.vertical.menu .menu .item.navbarItemMobile {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }
    #navbar.ui.menu .active.item.navbarItemMobile {
        border-radius: 6px;
    }
    #navbar.ui.menu .item.navbarItemMobile {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    .mobileNavbarIcon {
        margin-right: 8px;
    }
    .ui.accordion .title.accountAccordionTitle {
        display: flex;
        align-items: center;
        padding-top: 0px;
    }
    div .accountTitleDiv {
        white-space: nowrap;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    
    .ui.vertical.menu .menu .item.accordionAccountItem {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

	svg.myAccountMobileVector:not(:root) {
		margin-left: auto;
	}

	div .accountMobileTitleDiv {
		display: flex;
		align-items: center;
		width: 100%;
	}

    #navbar.ui.menu {
        margin-bottom: 24px;
    }

	#navbar .accountMenuItem.navbarItemMobile.switch-item {
		padding-top: 0;
		padding-bottom: 0;
	}
}