#dashboard {
    width: 100%;
    position: relative;
}

#dashboard .app-layout {
    margin-bottom: 124px;
}

@media only screen and (max-width: 767px) {
    #dashboard .ui.attached.tabular.menu {
        visibility: hidden;
    }
    #dashboard .ui.tabular.menu+.attached:not(.top).segment {
        margin-top: -50px;
    }
    #dashboard .ui.container {
        margin-left: 0em !important;
        margin-right: 0em !important;
    }
    #dashboard .ui.secondary.pointing.menu {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
    #dashboard {
        overflow-x: hidden;
    }
    #dashboard .app-layout {
        margin-bottom: 0px;
    }
}