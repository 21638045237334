.ui.padded.segment.footer,
.ui.segment .footer:last-child {
    padding: 0.1em;
    margin-top: 4em;
    padding-bottom: 2em;
}

.ui.grid>.column.termsfooter:not(.row) {
    margin-left: 0em;
}

.ui.grid>.column.amlfooter:not(.row) {
    margin-left: -1em;
}

.ui.grid>.column.faqfooter:not(.row) {
    margin-right: 1em;
    margin-left: -1em;
}

@media only screen and (max-width: 767px) {
    #footer .ui.padded.segment.footer {
        padding-left: 1em;
    }
}

#footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

@media only screen and (max-width: 767px) {
    #footer {
        position: relative;
    }
}

#footer .switcher i.flag {
    cursor: pointer;
}

#footer .switcher .item.active i.flag {
    opacity: 0.4;
}

#footer .footercopyright {
    float: right;
}