#registration .ui.checkbox {
    vertical-align: super;
    margin-bottom: 1em;
}

#registration .ui.header:last-child {
    margin-bottom: 0em;
    margin-left: 0.45em;
}

#registration .column .email {
    margin-top: -1em !important;
    margin-bottom: -0.5em;
}

#registration .ui.button.buttonStyle {
    min-height: 2rem;
    min-width: 10rem;
}

#registration .ui.form .field .prompt.label {
    border: none !important;
    padding: 0;
}

#registration .ui.form .field .prompt.label:before {
    display: none;
}

#registration .ui.selection.dropdown {
    /* margin-bottom: -1em !important; */
}

#registration .ui.secondary.pointing.menu {
    margin-left: 1em;
    margin-right: 1em;
}

.ui.selection.countrycode.dropdown {
    margin-top: 1.6em;
}

#registration div.registerbutton>.ui.button {
    padding-right: 3em;
    padding-left: 3em;
}

.ui.header.countrycodeheader:first-child {
    margin-bottom: -1.4em;
    padding-bottom: -1em;
    margin-top: 0.2em;
    font-size: 0.92857143em;
}

.ui.header.choosecountryheader {
    margin-bottom: 0.2em;
    font-size: 0.92857143em;
    font-weight: bolder;
}

#registration .ui.form .field>label {
    font-size: 0.85em !important;
    font-weight: bold;
}

#registration h5.validationsheader:first-child {
    margin-bottom: 0.3em;
}

#registration .ui.header.checkboxheader {
    margin-bottom: 0em;
}

#registration .aboutyoutitle {
    margin-bottom: -1em;
}

#registration .liablepersonheader {
    margin-top: 1em;
    margin-bottom: -1em;
}

@media only screen and (max-width: 767px) {
    #registration .ui.secondary.pointing.menu {
        padding: 2px;
        margin-left: 1px;
        margin-right: 5px;
    }
    #registration .ui.container {
        margin-left: 0px !important;
        margin-right: 0em !important;
    }
    #registration .ui.secondary.pointing.menu .item {
        margin-bottom: -4px;
        margin-left: -0.2em;
    }
    #registration h5.validationsheader:first-child {
        padding-bottom: 0.5em;
        padding-top: 0.5em;
    }
    #registration .ui.divider:not(.vertical):not(.horizontal) {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
    }
    #registration .aboutyoutitle {
        margin-bottom: -2em !important;
    }
    #registration .ui.form .field>label {
        margin-left: 4px;
    }
    #registration div.registerbutton>.ui.button {
        padding-right: 7em;
        padding-left: 7em;
        padding-bottom: 1em;
        padding-top: 1em;
    }
    #registration .ui.grid>.column:not(.row),
    .ui.grid>.row>.column {
        padding-left: 0em;
    }
    #registration .ui.selection.dropdown {
        line-height: 30px;
    }
    #registration .ui.dropdown>.text>.flag {
        margin-left: 10px;
    }
    #registration .ui.header.headerStyle:first-child {
        margin-top: 1em;
        margin-bottom: -0.2em;
        margin-left: 0.3em;
    }
    #registration .ui.selection.countrycode.dropdown {
        margin-top: 2em;
    }
    #registration .ui.form .disabled.field {
        margin: 1em;
    }
    #registration .column .firstname {
        margin-top: 2em !important;
        margin-bottom: -1em !important;
    }
    #registration .column .email {
        margin-top: -1em !important;
    }
    #registration .ui.header.choosecountryheader {
        margin-top: 1em;
    }
    #registration .column .countrycode {
        margin-top: 0em !important;
    }
    #registration .column .phonenumber {
        margin-top: -0.4em !important;
        margin-bottom: 0em;
    }
    #registration .ui.form .field .ui.input {
        width: 100%;
    }
    #registration .ui.dropdown>.text {
        margin-left: 1em;
    }
}