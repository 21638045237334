#upgrade-plan .mobile {
  display: none !important;
}

@media only screen and (max-width: 767px) {

      #upgrade-plan upgrade-plan.ui[class*="very basic"].table {
        margin-top: 0;
      }

      #upgrade-plan .computer.tablet {
        display: none !important;
      }

      #upgrade-plan .mobile {
        display: flex !important;
      }

      #upgrade-plan .plan.button {
        width: 10em;
      }
}