#myEmployees .ui.selection.dropdown>i.icon {
  float: right;
}

#myEmployees .ui.selection.dropdown {
  padding-right: 13.5px;
}

#myEmployees .ui.list.employeeDataList .list>.item .header, .ui.list>.item .header {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

#myEmployees .ui.header.myEmployeeHeader {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

#myEmployees .ui.header.employeeListHeader {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.employeeListHeader {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

#activeEmployeeAccordion .ui.accordion .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

i.icon.accordionOpenIcon {
  margin-left: auto;
}

#employeeAccordion .ui.button.removeBTN {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0px;
  background: none;
}

#myEmployees .ui.basic.primary.button.assignCardBTN {
  margin-left: 5px;
}

#employeeAccordion div.microCardCell {
  display: flex;
  align-items: center;
}

#employeeAccordion .microCard {
  margin-right: 13px;
  width: 44px;
  height: 28px;
  -moz-box-shadow: 4px 4px 2px #ccc;
  -webkit-box-shadow: 4px 4px 2px #ccc;
  box-shadow: 4px 4px 2px #ccc;
}

#employeeAccordion .employeeCardTable {
  margin-top: 7px;
}

#employeeAccordion .saveBTN {
  margin-left: 8px;
  margin-right: 9;
}

#employeeAccordion .employeeName {
  margin-left: 19px;
}

#employeeAccordion .assignCardBTN {
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0px;
}

#employeeAccordion .plusOutlineIcon {
  margin-right: 10px;
}

#employeeAccordion .ui.grid>.row>.column.eRadioCLMN {
  padding-right: 0px;
  margin-right: -22px;
}

#employeeAccordion .ui.grid>.row>.column.cardsCLMN {
  padding-left: 0px;
}

#employeeAccordion div.acceptDeclineBTNS>.ui.button {
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  border-radius: 6px;
  padding: 7px, 16px, 7px, 12px;
}

#employeeAccordion div.acceptDeclineBTNS {
  margin-left: auto;
  margin-right: 10px;
}

#employeeAccordion .declineBTN {
  margin-left: 3px;
}

#employeeAccordion .declineInnerBTN {
  margin-left: 3px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
}

#createEmployee label.formLabel {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0px;
}

#createEmployee .createEmployeeBTN {
  font-size: 16px;
  font-weight: 500;
}

#createEmployee .createEmployeeHeader {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

#createEmployee input.employeeEmailInput {
  margin-top: 4px;
}

.ui.radio.employeeRadio.checkbox {
  padding-right: 18px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
}

#createEmployee .syncIcon {
  float: right;
}

div.employeeRadio {
  padding-right: 25px;
}

div.employeeRadioDiv {
  display: flex;
}

#employeeAccordion .ui.button.discardBTN {
  font-weight: 400;
}

.ui.radio.employeeRadio.checkbox {
  margin-right: 25px;
}

#attachment-row{
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: -18px;
}

[data-letters]:before {
  content:attr(data-letters);
  display:inline-block;
  font-size:1em;
  font-weight: bold;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background:#E6EBF1;
  vertical-align:middle;
  margin-right:1em;
  color:#586879;
  }

  #clipboard-button {
    box-shadow: none !important;
    margin-right: 0px;
    padding: 5px;
  }

  #edit-role {
    box-shadow: none !important;
    margin-right: 0px;
    padding: 5px;
  }

  @media only screen and (max-width: 768px) {
    #employeeAccordion div.acceptDeclineBTNS {
      display: none;
    }

    #employeeAccordion .ellipseAccordionPenEmp {
      margin-left: auto;
    }

    #myEmployees .ui.basic.primary.button.assignCardBTN {
      margin-left: 0px;
    }

    #myEmployees .myEmployeeHeader {
      padding: 0 1rem;
    }

    #myEmployees .myEmployeeButtons {
      padding: 0 1rem;
    }
  }
