.ui.button.exportButton {
  margin-left: 8px;
  height: 36px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
}

.ui.borderless.pagination.secondary.pagefilter.menu .item {
  min-width: 2em;
}

.ui.borderless.pagination.secondary.pagefilter.menu .icon.item {
  margin-left: -1em;
  margin-right: -1em;
}

.ui.pagination.pagefilter.menu .active.item {
  min-width: 2em;
}

.ui.pagination.pagefilter.menu .item {
  margin-top: 1em;
}

.ui.menu .icon.item>.icon.pagefilter {
  margin-right: 0.5em;
}

.ui.menu .icon.item>.icon.pagefilter {
  margin-left: 0.5em;
}

.ui[class*="right floated"].segment.filterSegment {
  margin-top: 2.3em;
}

#transactionstatements .ui.header.headerMargin {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

#transactionstatements .ui.header .content {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#transactionstatements .ui.table thead th {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

#transactionstatements .ui.basic.table.tableLocation {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#transactionstatements .transactionSegment.ui.segment {
  border: 1px;
  box-shadow: none;
}

#transactionstatements .ui.menu {
  border: 0px;
}

.ui.selection.dropdown.exportSelect {
  margin-left: 8px;
}

#transactionDetailsModal .ui.list>.item .header {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

#transactionDetailsModal span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#transactionDetailsModal .ui.header.transactionModalHeader {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 24px;
  margin-left: 0px;
}

#transactionDetailsModal .ui.button.modalBTNS {
  margin-right: 12px;
  font-weight: 400;
}

#transactionDetailsModal .ui.basic.button.modalOpenBTN {
  border: 0px solid transparent;
}

#transactionDetailsModal .ui.grid>.row>.column.modalBTNSCLMN {
  padding-left: 0px;
}

@media only screen and (max-width: 767px) {

  .scrolling.dimmable.dimmed > .dimmer {
    padding-top: 0px;
    padding-left: 0px;
  }

  .modals.dimmer .ui.scrolling.modal.filtersModal {
    margin-top: 0px;
  }

  .ui.active.modal.filtersModal {
    height: 100%;
    width: 100%;
  }
  .ui.button.mobileClearBTN {
    font-weight: 400;
  }
  .ui.button.mobileApplyBTN {
    font-weight: 500;
    padding-bottom: 12px;
  }
  .ui.grid>.row>.column.mobileApplyCLMN {
    padding-right: 0px;
  }
  #transactionstatements .ui.basic.table thead, .ui.basic.table tfoot {
    visibility: hidden;
  }
  #transactionstatements .ui[class*="right floated"].segment.filterSegment {
    margin-bottom: -10em;
    margin-right: 6em;
    margin-top: -3.5em;
    padding-left: 0.2em;
  }
  #transactionstatements .ui.basic.table thead, .ui.basic.table tfoot {
    height: 9em;
  }
  #transactionstatements .ui.table:not(.unstackable) tbody.tabletcomputertable {
    /* visibility: collapse !important; */
  }
  #transactionstatements .ui[class*="very basic"].table:not(.sortable):not(.striped) td.tablestatusmobile:last-child {
    margin-left: 17em;
    margin-top: -2em;
  }
  .ui[class*="very basic"].table {
    margin-top: -9em;
  }
  #transactionstatements .ui[class*="very basic"].table:not(.sortable):not(.striped) td.amount {
    margin-left: 17em;
    margin-top: -2em;
  }
  #transactionstatements .ui.grid.inputbuttonmobilegrid {
    margin-left: 0px;
    margin-right: 0px;
  }
  #transactionstatements .ui.table:not(.unstackable) tr>td.statementsdropdown {
    visibility: hidden;
  }
  #transactionstatements .btnsMobile {
    height: 36px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    padding: 0px;
    padding-left: 13px;
    padding-right: 13px;
  }
  #transactionstatements .mobileBTNRow {
    padding-top: 0px;
  }
  #transactionstatements .exportMobileCLMN {
    padding-left: 4px;
  }
  #transactionstatements .filterMobileCLMN {
    padding-right: 4px;
  }
  #transactionstatements .ui.grid>.row>.column.mobileApplyCLMN {
    padding-right: 0px;
  }
  .ui.button.closeFiltersBTN {
    padding: 0px ;
    background: transparent ;
  }

  .ui.modal.filtersModal > .header:not(.ui) {
    display: flex;
    align-items: center;
  }
  .ui.fullscreen.modal.filtersModal>.header {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }
  .ui.fullscreen.modal.filtersModal {
    height: 100%;
    width: 100% !important;
  }

  .ui.modal.filtersModal .content > .description {
    padding-left: 6px !important;
  }

  #transactionstatements .exportMobileCLMN > .exportSelect.btnsMobile {
    display: flex;
    align-items: center;
  }

  .ui.secondary.menu.filtersModalMenu  .active.item{
    
    }

    .ui.modal > .actions.filtersModalActions {
      position: absolute;
      bottom: 15px;
      width: 100%;
    }
}