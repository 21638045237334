#crypto .balance-header {
    color: #364657;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

#crypto .balance {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    padding: 0;
}

#crypto .positive {
    background: #C6EFCF;
    color: #00761A;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
}

#crypto .negative {
    background: #FFDCDC;
    color: #B60606;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
;
}
#crypto .transactionSegment {
    padding: 0;
}

#crypto #transactionstatements {

}

#crypto .currency-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1C2630;
}

#crypto .currency-symbol {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #586879;
    padding: 0;
}

#crypto-trade .stage {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    padding-left: 24px;
    padding-top: 24px;
}

#crypto-trade .amount {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #0084A0;
    text-align: center;
}

#crypto-trade .buy-window {
    max-width: 540px;
}

#crypto-trade form.ui.form {
    padding: 60px 24px 48px;
}

#crypto-trade .confirm-list .header {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #586879;
}

#crypto-trade .confirm-list .item {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
}

#crypto-trade .confirm-window .content{
    padding-right: 48px;
    padding-left: 48px;
    padding-top: 48px;
}

#crypto-trade .confirm-window {
    padding-top: 24px;
}

#crypto-trade div .moneyTopupAmount {
    display: flex;
    align-items: baseline;
    padding-bottom: 34px;
    justify-content: center;
    max-width: 120px;
}

#crypto-trade .ui.header.currencyH2 {
    padding-left: 8px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #0084A0;
}

#crypto-trade .ui.form .field .ui.input.currencyInput input {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0;
    text-align: right;
    color: #0084A0;
}
