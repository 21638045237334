#cardDashboard svg.appleWallet {
  margin-top: 16.78px;
}

#cardDashboard .ui.label.carddashboardLabel {
  background: transparent;
  margin-left: -10px;
}

#cardDashboard .ui.header.activatecardHeader {
  margin: 0px;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

#cardDashboard .ui.primary.button.activatecardBTN {
  margin-bottom: 48px;
}

#cardDashboard p.activatecardP {
  margin-bottom: 16px;
}

#cardDashboard .ui.icon.button.activatecardcloseBTN {
background: transparent;
}

#cardDashboard .ui.button:not(.icon.downloadbtnIcon ) > .icon:not(.button):not(.dropdown) {
  color: #1C2630 ;
}

#cardDashboard .ui.modal .actions > .button{
  margin-left: 0px;
}

.ui.grid > .column .newcardmainCLMN:not(.row) {
  padding-left: 0px;
  padding-right: 0px;
}

