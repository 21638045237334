#messages {
    cursor: pointer;
    border-radius: 6px;
}

#messages .content.messageListContent {
    padding: 10px 10px 10px 24px;
}

#messages .chervonRightIcon {
    color: #586879;
    padding: 20px 20px 0px 0px;
}

#messages .unreadicon {
    padding-right: 1em;
    position: relative;
    bottom: 2px;
}

.ui.divider.selected {
    display: none;
}

.ui.searchInput>button, .ui.searchInput>button:hover, .ui.searchInput>button:focus {
    background: #FFFFFF;
    border: solid rgba(34, 36, 38, 0.15);
    border-width: 1px 1px 1px 0px;
}

#InboxTab .ui.button:not(.icon.downloadbtnIcon)>.icon:not(.button):not(.dropdown) {
    color: #AAB7C4 !important;
}

#InboxTab .header .ui.divider {
    margin: 1rem 0px 1rem 0px;
}

#InboxTab .ui.header .icon:only-child {
    display: flex;
}

.ui.header.emptyInbox {
    text-align: center;
    padding: 40px;
}

#messageDetails .ui.header {
    font-size: 20px;
}

#messageDetails .messageContent .header {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
}

#messageDetails .messageContent .content {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
}

.backchevronitem.messages {
    display: none !important;
}

#messageDetails .ui.items>.item .meta {
    margin: 0;
}

div .mobilePagination {
    display: none;
}

.ui.grid>.row.mobileInboxBTNS {
    display: none;
}

.ui.grid>.row>.column.desktopInboxBTNS {
    display: initial;
}

  div .outgoingBTN {
    margin: 0px 8px 0px 8px !important;
}

.chatMessage {
    width: 75%;
}

.incidentMessages{
    overflow-y: auto;
    max-height: 50vh;
}

@media only screen and (max-width: 768px) {
    .chatMessage {
        width: 100%;
    }

    .incidentMessages{
        overflow-y: unset;
        height: unset;
    }

    .column.ticketOpened, .ui.pagination.ticketOpened {
        display: none !important;
    }
    .backchevronitem.messages {
        display: inline !important;
    }
    /* #InboxTab .ui.header .icon:only-child {
        display: none;
    } */
    div .desktopPagination {
        display: none;
    }
    div .mobilePagination {
        display: initial;
    }
    
    div .mobilePagination .item:first-child{
       padding-left: 0px;
    }
    
    div .mobilePagination .item:last-child{
       padding-right: 0px;
    }
    
    .ui.grid>.row.mobileInboxBTNS {
        display: initial;
        padding-top: 0px;
    }
    .ui.grid [class*="right floated"].column.inboxSearchCLMN {
        padding-bottom: 0px;
    }
    #InboxTab .ui.header .icon:only-child {
        margin-right: 0px;
    }
    .ui.grid>.row>.column.desktopInboxBTNS {
        display: none;
        padding-bottom: 0px;
    }
    .ui.grid>.row.desktopInboxBTNSRow {
        padding-bottom: 0px;
        max-height: 70px;
    }

    @media only screen and (max-width: 767px) {
        .inboxSearchCLMN {
            padding: 0 1rem !important;
        }
    }
}