#transferBetweenAccountsConfirmation .item {
    font-size: 16px;
    line-height: 24px;
}

#transferBetweenAccountsConfirmation .item .header {
    font-size: 14px;
    line-height: 22px;
}

#uploadedFiles {
    display: block;
    box-sizing: inherit;
}

.fileName {
    padding-left: 10px;
}

.withoutBorder {
    box-shadow: unset !important;
    border-radius: unset !important;
    border: unset !important;
}

#insufficientFunds{
	background: #FFFFFF !important; 
    color: #D62424 !important;
	font-size: 12px;
	font-weight: bold;
}