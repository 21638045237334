@media (min-width: 30em) {
	#onfido-mount {
	  position: relative;
	  top: 10%; 
	  z-index: 10; 
	}
  } 

  @media (max-width: 479px) {
	#onfido-mount {
	  top: 10%; 
	  z-index: 10; 
	}

	.onfido-sdk-ui-Modal-inner {
		font-size: 1em;
		height: 80vh;
    	width: 95vw;
	}	
  } 

  /* Term of agreement pop-up put on top,when user clicks do not agree*/
  .onfido-sdk-ui-Theme-modalOverlay{
	  z-index: 2000;
  }
 

