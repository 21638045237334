.card.user-info {
    box-shadow: none;
}

.ui.segment.usersegment:first-child {
    margin-top: 3em;
}

#profile-card.ui.card {
    width: 100%;
    box-shadow: none;
}

#profile-card.ui.card>.content:first-child {
    padding-bottom: 0;
}

#profile-card.ui.card>.content {
    border-top: none;
}

#profile-card .header {
    margin-top: 1rem;
    font-size: 1.58em;
}

#profile-card .ui.list {
    font-size: 1.15rem;
}

#profile-card .ui.list>.item {
    padding: 0em 0em 1em;
}

#profile-data.ui.list .item .description {
    font-size: 1.15em;
    line-height: 1.6em;
}

#profile-data.ui.list .item .header {
    line-height: 1.6em;
    display: block;
    margin: 0em;
    font-weight: bold;
}

#profile-data.ui.list .ui.divider {
    margin: 0.5em 0;
}

.verification-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.verification-label i {
    vertical-align: text-top;
}

.ui.header.profileHeader {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
}

.ui.button.closeTarget, .ui.button.closeTarget:hover {
    background: transparent;
    padding: 0px;
    float: right;
}

.ui.grid>.column.countryCodeCLMN:not(.row) {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
}

.ui.grid>.column.phoneGrid:not(.row) {
    padding-bottom: 22px;
    padding-right: 0px;
}

.ui.form.editForm .field>label {
    margin-top: 4.5px;
    margin-right: 7.5px;
    margin-bottom: -3px;
}

#openBusinessModal .ui.form .field>label {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

.businessPaymentLBL {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

div .editBTNS {
    display: flex;
    align-items: baseline;
}

.ui.button.discardBTN {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 6px;
    padding: 10px, 20px, 10px, 20px;
}

.ui.grid.editGrid {
    margin: 0px;
}

.ui.selection.dropdown.fromAccountDropdown {
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
    margin-bottom: 16px;
    padding-right: 13.5px;
    border-radius: 5px;
}

.fromAccountDropdown>i.icon {
    float: right;
}

.ui.message.amountMSG {
    margin-top: 0px;
    padding: 10px 12px 10px 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.ui.clearing.divider.businessPaymentDivider {
    margin: 32px 2px 32px 2px
}

.ui.primary.button.payBusinessBTN {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}

.ui.basic.primary.button.link-button {
    float: right;
}

#profile-data .profileLink {
    cursor: pointer;
}

.myCompanies p {
    display: inline-block;
    vertical-align: top;
    padding-right: 0.25rem;
    margin-bottom: 0px;
}

.myCompanies p.capitalize {
    text-transform: capitalize;
}

.myCompanies p.companyName {
    font-weight: 500;
}

.editMobileForm.ui.form .field .prompt.label {
    border: none!important;
    padding: 0;
}


@media only screen and (max-width: 768px) {
    .ui.padded.segment.profilesegment {
        padding-left: 5px;
        padding-right: 5px;
    }
    .ui.stackable.grid>.column.profiledatacolumn:not(.row) {
        padding-top: 0px !important;
    }
    .ui.stackable.grid>.column.useredatacolumn:not(.row) {
        padding-bottom: 0px !important;
    }
    .ui.segment.usersegment:first-child {
        margin-top: 36px;
        padding: 0px;
    }
    div .businessAccountItems {
        display: none;
    }
}