.card.user-info {
  box-shadow: none;
} 

.services .ui.tabular.menu .active.item {
  border:none;  
}

.services .ui.tabular.menu .item {
  border:none;
}

.services .ui.tabular.menu {
  border-bottom: 0px;
}

.services .ui.attached.segment {
  border: 0px;
}

.notificationCount {
  border-radius: 15px;
  color: white;
  margin-left: 6px;
  font-size: 14px;
  padding: 3px;
  margin-bottom: -3px;
  display: inline-block;
  text-align: center;
}