#apple-badge-img {
	border-radius: 13px;
	height: 85px;
	width: 250px;
	padding-top: 15px;
}

#google-badge-img {
	height: 100px;
	width: 250px;
}

#twoFA-enable .ui.breadcrumb {
	padding-left: 30px;
	padding-top: 32px;
}

#twoFA-enable .form.ui.form {
	width: 100%;
	margin: 0 1rem;
	text-align: center;
}

#twoFA-enable h1 {
	text-align: center;
}

@media only screen and (max-width: 600px) {
	#apple-badge-img {
		border-radius: 13px;
		height: 85px;
		width: 150px;
		padding-top: 15px;
	}

	#backup-description {
		padding-left: 15px;
		padding-right: 15px;
	}

	#google-badge-img {
		height: 85px;
		width: 160px;
		padding-top: 15px;
	}

	.twofamodal {
		width: 98%;
	}
}
