.onBehalfOf {
	float: right;
}

.welcomeText {
	font-weight: 600;
    font-size: 28px;
    line-height: 36px;
}

.welcome .first {
	padding-bottom: 0px !important;
}

.welcome .second {
	padding-top: 0px !important;
}

.mobile.first {
	padding-bottom: 0px !important;
}

.mobile.second {
	padding-top: 0px !important;
}

@media only screen and (max-width: 767px) {
	.welcome {
		width: 100%;
	}
}