#stockTicket .descriptiontextarea textarea {
    width: 100%;
	border-radius: 5px;
}

#stockTicket .ui.padded.segment {
	width: 50%;
}

#stockTicket label {
	font-weight: 600;
}

@media only screen and (max-width: 768px) {
	#stockTicket .ui.padded.segment {
		width: 100%;
	}
}
