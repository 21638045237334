#shareTransactionModal.ui.modal > .close.inside + .header {
    padding-right: 2.25em;
}
#shareTransactionModal.ui.modal > .close.inside {
    top: 1.0535em;
    right: 1em;
}

#shareTransactionModal.ui.header {
    font-weight: 600;
    size: 22px;
    line-height: 30px;
}

#shareTransactionDescription {
    margin-top: 1em;
    font-weight: 400;
    size: 14px;
    line-height: 22px;
}