.resendLink:hover {
    cursor: pointer;
}

#confirmPhone .segment {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
}

#confirmPhone .header {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    padding: 0;
}

#confirmPhone .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
}

#confirmPhone .form {
    width: 100%;
    padding: 1rem;
}

#confirmPhone .form .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #364657;
    
}

#confirmPhone .form .inputDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #364657;
}

#confirmPhone .form .button button {
    padding-left: 3em;
    padding-right: 3em;
}

#confirmPhone .form .ui.pointing.label, .ui[class*="pointing above"].label {
    margin-top: 0;
}