#businessCards i.icon.accordionOpenIcon {
	margin-left: auto;
}

#businessCards .titleRow {
	padding-top: 0px !important;
    padding-bottom: 0px !important;
}

#businessCards .tab-value {
	font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

#businessCards .tab-hold {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

#businessCards .tab-name {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

#businessCards .assigned-value {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

#businessCards .edit-account-label {
    padding: 0.25em;
	cursor: pointer;
	margin-left: 2px;
}

#businessCards .businessCardBTNs {
    display: flex;
    justify-content: space-between;
}

#businessCards .businessCardBTNs div:last-child {
    margin-left: auto;
}
