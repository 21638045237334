#card-image {
    position: relative;
    padding: 0;
}

#card-data {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#card-container {
    height: 100%;
    position: relative;
}

#card-number {
    position: absolute;
    bottom: 25%;
    right: 10%;
    font-size: 2em;
}

#valid-thru {
    position: absolute;
    bottom: 10%;
    right: 10%;
    font-size: 0.7em;
    line-height: 1.5em;
}

#card-holder {
    position: absolute;
    bottom: 10%;
    right: 30%;
    font-size: 0.7em;
    line-height: 1.5em;
}

@media only screen and (max-width: 379px){
    #card-number {
        font-size: 1.2em;
    }

    #valid-thru {
        font-size: 0.45em;
    }

    #card-holder {
        font-size: 0.45em;
        right: 28%;
    }
}

@media only screen and (min-width: 380px) and (max-width: 436px){
    #card-number {
        font-size: 1.7em;
    }

    #valid-thru {
        font-size: 0.6em;
    }

    #card-holder {
        font-size: 0.6em;
        right: 28%;
    }
}

@media only screen and (min-width: 900px) and (max-width: 991px){
    #card-number {
        font-size: 2em;
    }

    #valid-thru, #card-holder{
        font-size: 0.6em;
    }
    #card-holder {
        right: 25%;
    }
}

@media only screen and (min-width: 991px) and (max-width: 1023px){
    #card-number {
        font-size: 1.2em;
    }

    #valid-thru, #card-holder{
        font-size: 0.45em;
    }
    #card-holder {
        right: 28%;
    }
}

@media only screen and (min-width: 1024px) {
    #card-number {
        font-size: 1.2em;
    }

    #valid-thru, #card-holder{
        font-size: 0.45em;
    }
}



