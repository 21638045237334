#bulk-top-up .section-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

#bulk-top-up .text {
    font-size: 16px;
    line-height: 24px;
}

#bulk-top-up .icon {
    margin-right: 10px;
}

#bulk-top-up .padding-left {
    padding-left: 20px;
}

#bulk-top-up .template-download {
    padding: 0;
}

#bulk-top-up .container {
    padding: 24px 24px 12px 24px;
}

#bulk-top-up-confirm .container {
    padding: 24px;
}

#bulk-top-up-confirm .section-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

#bulk-top-up-confirm .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

#bulk-top-up-confirm th {
    font-size: 13px;
    line-height: 18px;
}

#bulk-top-up-confirm td {
    font-size: 13px;
    line-height: 18px;
}

#bulk-top-up-confirm .center{
    text-align: center;
}

#bulk-top-up-confirm .total-header{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

#bulk-top-up-confirm .total-amount{
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

#bulk-top-up-confirm .total {
    margin-left: auto;
    padding-right: 24px;
    text-align: right;
}
#bulk-top-up-confirm .warning {
    font-size: 13px;
    line-height: 18px;
}

#editBulkTopUpEntryModal label {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}
