#newcard .ui.label:first-child {
    background-color: transparent;
    padding: 0px;
    margin-bottom: 5px;
}

#newcard .ui.header:first-child {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

#newcard .ui.basic.primary.button {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
}

#newcard .ui.header.totalpriceheader {
    margin: 0px
}

#newcard img.newcardOption {
    width: 151px;
    height: 96px;
    margin: 16px;
    margin-left: 0px; 
	border-radius: 6px;
    -moz-box-shadow: 10px 10px 5px #ccc;
    -webkit-box-shadow: 10px 10px 5px #ccc;
    box-shadow: 10px 10px 5px #ccc;
}

#newcard .ui.items>.item.yourcardItem {
    align-items: center;
    padding: 1em;
    border-radius: 4px;
    cursor: pointer;
}

#security-info {
    border-radius: 6px;
    padding: 12px;
}
