#createaccount .ui.form .field .prompt.label {
    padding: 0px;
}

#createaccount .ui.form {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

#createaccount .ui.fluid.dropdown {
    margin-bottom: 1em;
}

#createaccount .ui.primary.button {
    font-weight: 500;
    font-size: 16px;
}

.submitSegment.ui.segment:first-child {
    margin-top: 0em;
    padding: 6em;
}